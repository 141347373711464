<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientTransform="matrix(1 0 0 -1 0 184)" gradientUnits="userSpaceOnUse" id="SVGID_1_" x1="31.3" x2="151.7" y1="92.5068" y2="92.5068">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="M93.5,65.2v6.4c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5v-6.4c0-0.8,0.7-1.5,1.5-1.5S93.5,64.4,93.5,65.2z   M151.7,80.5v39.6c0,8.3-6.7,15-15,15H46.3c-8.3,0-15-6.7-15-15l0,0V80.5c0-8.3,6.7-15,15-15H74c0-2,0.4-4,1.1-5.9  c0.9-2.5,2.3-4.7,4.2-6.5l0.6-0.6c1.3-1.2,2.7-2.1,4.2-2.9c0.3-0.1,0.5-0.2,0.8-0.4s0.6-0.2,0.9-0.4c9.3-3.4,19.7,1.4,23.1,10.7  c0.7,1.9,1.1,3.9,1.1,5.9h26.7C145,65.5,151.7,72.3,151.7,80.5z M80,65.8c0,6.6,5.4,12,12,12s12-5.4,12-12c0-0.1,0-0.2,0-0.3  c0-6.6-5.4-12-12-12s-12,5.4-12,12V65.8z M77.3,91.5h-4.7l-3.3,10.8l-0.9,3.5l-1-3.5l-3.2-10.8h-4.9l6.3,17.8H71L77.3,91.5z   M104.1,91.5h-4.8l-2.9,14.2l-3.3-14.2h-4.2l-3.2,14.2l-3-14.2h-5l5.1,17.8H88l2.1-8.6l0.9-4.4l0,0l0.8,4.3l2.1,8.6H99L104.1,91.5z   M122.7,100.4c0.1-1.7-0.3-3.4-1.1-4.9c-0.7-1.3-1.7-2.4-3-3.2c-1.4-0.8-3-1.1-4.6-1.1c-1.6,0-3.2,0.3-4.7,1.1  c-1.3,0.7-2.3,1.8-3,3.2c-1.4,3.1-1.4,6.7,0,9.8c0.7,1.3,1.7,2.4,3,3.2c1.4,0.8,3,1.1,4.7,1.1c1.6,0,3.2-0.3,4.6-1.1  c1.3-0.7,2.3-1.8,3-3.2C122.4,103.8,122.7,102.1,122.7,100.4z M116.9,96.3c-1.3-1.6-3.7-1.9-5.4-0.6c-0.2,0.2-0.4,0.4-0.6,0.6  c-1.4,2.6-1.4,5.7,0,8.3c1.3,1.6,3.8,1.9,5.4,0.5c0.2-0.2,0.4-0.3,0.5-0.5C118.3,102,118.3,98.9,116.9,96.3z M81.9,51  c-0.7,0.5-1.4,1-2,1.6C80.5,52,81.2,51.5,81.9,51z M93.5,59.8c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3L93.1,59  c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.1-1,0.1-1.4,0.4l-0.2,0.2  c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.4,0.2,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4  c0.4,0,0.8-0.2,1.1-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6C93.5,60,93.5,59.9,93.5,59.8L93.5,59.8z" style="fill:url(#SVGID_1_);"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>